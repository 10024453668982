.Home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.hero_wrapper {
  background: url("../Assets/images/img/hero-bg.jpg");
  background-size: cover;
  min-height: 100vh;
}
.btn {
  background: #3498db;
  background-image: -webkit-linear-gradient(top, #3498db, #2980b9);
  background-image: -moz-linear-gradient(top, #3498db, #2980b9);
  background-image: -ms-linear-gradient(top, #3498db, #2980b9);
  background-image: -o-linear-gradient(top, #3498db, #2980b9);
  background-image: linear-gradient(to bottom, #3498db, #2980b9);
  -webkit-border-radius: 28;
  -moz-border-radius: 28;
  border-radius: 28px;
  font-family: Arial;
  color: #ffffff;
  font-size: 26px;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  border: none;
}

.btn:hover {
  background: #3cb0fd;
  background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -moz-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -ms-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -o-linear-gradient(top, #3cb0fd, #3498db);
  background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
  text-decoration: none;
}

.about, .services{
  font-family: "Roboto";
  padding: 20px;
  text-align: justify;
}
.content {
  display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 15px;
  /* padding: 50vh 20px; */

}

.header {
  font-size: 54px;
  font-weight: 700;
  font-family: "Roboto";
  
}
.title {
  font-family: cursive;
  font-size: 32px;
} 
.text {
  color: gray;
  letter-spacing: 0.04rem;
  line-height: 1.5rem;
}
.about_content{
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 800px;
  width: 100%;
  text-align: justify;
  margin: 0 auto;
}
.about_content .header, .title{
  text-align: center;
}
.services {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #e1ecf3;
}

.services_content {
  
  width: 100%;

  display: flex;
   
    flex-direction: row;
    justify-content: space-between;
}
.img_container{
  display: flex;
  justify-content: center;
}
.serviceImg{
  width: 100%;
  height: 50%;
}

/* .header {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
} */

.card_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.card_content {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
  transition: transform 0.3s ease;
}

.card_content p:first-of-type {
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
}

.card_content:hover {
  transform: translateY(-5px);
}

/* @media (min-width: 768px) {
  .services_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .card_wrapper {
    margin-bottom: 0;
  }

  .card_content {
    max-width: 300px;
    margin: 1rem;
  }
} */
 .text div{
  display: flex;
  justify-content: center;
  align-items: center;
 }
 button{
  color: white;
  background-color: blue;
  text-decoration: none;
  outline: 0;
  border: 0;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;

 }
 a{
  text-decoration: none;
  color: white;
 }

@media (max-width: 767px) {
  .services_content {
    flex-direction: column;
  }

  .card_content {
    margin-bottom: 1.5rem;
    max-width: 100%;
  }
}