.testimonial_section {
    padding: 2rem;
    text-align: center;
    background-color: #f4f9f9;
  }
  
  .header {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .carousel {
    width: 100%;
    max-width: 900px;
    
    margin: 0 auto;
  }
  
  .testimonial {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
  }
  
  .avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-bottom: 1rem;
    object-fit: cover;
    border: 2px solid black;
  }
  
  .testimonial p {
    font-style: italic;
    margin-bottom: 0.5rem;
  }
  
  .testimonial span {
    font-weight: bold;
    color: #555;
  }
  /* Target the next button */
.swiper-button-next {
    color: #fff; /* Button color */
    background-color: #00aaff; /* Background color */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Target the previous button */
  .swiper-button-prev {
    color: #fff;
    background-color: #00aaff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Change the color of the arrow icon */
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 18px; /* Change the size of the arrow */
    color: #fff;     /* Change the arrow color */
  }
  
  /* Hover effect */
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background-color: #0088cc; /* Darker blue on hover */
    transform: scale(1.1); /* Slightly enlarge on hover */
    transition: transform 0.2s ease, background-color 0.2s ease;
  }