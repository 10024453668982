body{
  position: relative;
}
.nav{
  text-decoration: none;
  
}
.nav_bar {
  display: flex;
  flex-direction: row;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-inline: 1rem;
  justify-content: space-between;
  float: left;
  align-items: center;
background-color: white;

}

.logo {
  width: 40px;
}
.nav_menu{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.nav_links {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.nav_toggle {
  display: none;
  
}


.nav_link {
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
  font-weight: normal;
}

.nav_link:last-child {
  margin-right: 0;
  padding: 7.5px 15px;
  background-color: rgb(156, 156, 192);
  border-radius: 5px;
}
.nav_link:last-child .nav{
  color: #ffffff!important;


}

.nav_link:not(:last-child) {
  margin-right: 2.5rem;
}
.nav_link:not(:last-child) .nav{
  color:rgb(156, 156, 192) ;
}



.nav_icon {
  width: 28px;
  height: 28px;
  cursor: pointer;
  outline: none;
}

.sidebar {
  position: absolute; 
  top: 0;
  left: 0;
  width: 100%; 
  height: 100vh;
  display: none;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out; 
}

.sidebar.flex {
  display: block;
  transform: translateX(0); 
  background-color: ;
  top: 15vh;
  height: fit-content;
  padding: 10px;
  margin: 10px;
  border: 2px solid blue;
  background-color: white;
    z-index: 1000;
    width: 90%;
}


.side_bar .nav_link {
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
  font-weight: normal;
}

.nav_links_mobile .nav_link:last-child {
  margin-bottom: 0;
}

.nav_links_mobile .nav_link:not(:last-child) {
  margin-bottom: 0;
}


@media only screen and (max-width: 768px) {
  .nav_links {
    display: none;
  }

  .nav_toggle {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    
  }

  .nav_links_mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
   margin: 10px;
  
  }
}