/* ContactUs.module.css */

.contact_section {
  padding: 20px;
  background-color: #a4d4d4;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin: 0 auto;
}

.header {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #333;
}

.container{
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  gap: 20px;
  width: 100%;
}

.contact_form {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  gap: 20px;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form_group {
  padding: 10px;
  text-align: left;
}

.form_group label {
  display: block;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
}

.form_group input,
.form_group textarea {
  
  
 
}

.submit_btn {
  background-color: #00aaff;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: fit-content;
}

.submit_btn:hover {
  background-color: #0088cc;
}

/* Contact details */
.contact_details {
  flex: 1;
  max-width: 400px;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: fit-content;
}

.contact_details h3 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
  color: #333;
}

.contact_details p {
  font-size: 1rem;
  margin: 0.5rem 0;
  color: #555;
}

.footer{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.socials{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
@media screen and (max-width: 768px) {
  .form_group input,
  .form_group textarea {
    max-width: 200px;
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 0 auto;
  }
  .container{
    display: flex;
    flex-direction: column;
  }
}
